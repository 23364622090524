import { useState } from 'react';
import { BrowserRouter, Link } from 'react-router-dom';
import RequestAccessModal from '../components/request-access-modal';
import { Container, Button } from '@gitlab-rtsensing/component-library';
import './unauthorized-page.scss';

type AdminMessage = {
  action: 'access request successful';
};

const UnauthorizedPage = (props: any) => {
  const [requestAccessModalOpen, setRequestAccessModalOpen] = useState(false);
  const [accessRequestSuccessful, setAccessRequestSuccessful] = useState(false);

  function handleRequestAccessClick() {
    setRequestAccessModalOpen(true);

    const abortController = new AbortController();
    window.addEventListener(
      'message',
      (event: MessageEvent<AdminMessage>) => {
        if (
          event.origin !== window.location.origin ||
          event.data.action !== 'access request successful'
        ) {
          return;
        }
        abortController.abort();
        setAccessRequestSuccessful(true);
      },
      { signal: abortController.signal },
    );
  }

  return (
    <BrowserRouter>
      <Container>
        <div className="unauth-inner-content">
          <svg
            width="85"
            height="105"
            viewBox="0 0 85 105"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="unauth-lock-svg"
          >
            <path
              d="M42.5 57.75C40.8826 57.7313 39.2982 58.2027 37.9602 59.1008C36.6221 59.9989 35.5945 61.2806 35.0158 62.7732C34.4371 64.2659 34.335 65.8979 34.7233 67.4497C35.1116 69.0014 35.9715 70.3984 37.1875 71.4525V78.75C37.1875 80.1424 37.7472 81.4777 38.7435 82.4623C39.7398 83.4469 41.091 84 42.5 84C43.909 84 45.2602 83.4469 46.2565 82.4623C47.2528 81.4777 47.8125 80.1424 47.8125 78.75V71.4525C49.0285 70.3984 49.8884 69.0014 50.2767 67.4497C50.665 65.8979 50.5629 64.2659 49.9842 62.7732C49.4055 61.2806 48.3779 59.9989 47.0398 59.1008C45.7018 58.2027 44.1174 57.7313 42.5 57.75ZM69.0625 36.75V26.25C69.0625 19.2881 66.264 12.6113 61.2825 7.68845C56.3011 2.76562 49.5448 0 42.5 0C35.4552 0 28.6989 2.76562 23.7175 7.68845C18.736 12.6113 15.9375 19.2881 15.9375 26.25V36.75C11.7106 36.75 7.65685 38.4094 4.66799 41.3631C1.67913 44.3168 0 48.3228 0 52.5V89.25C0 93.4272 1.67913 97.4332 4.66799 100.387C7.65685 103.341 11.7106 105 15.9375 105H69.0625C73.2894 105 77.3432 103.341 80.332 100.387C83.3209 97.4332 85 93.4272 85 89.25V52.5C85 48.3228 83.3209 44.3168 80.332 41.3631C77.3432 38.4094 73.2894 36.75 69.0625 36.75ZM26.5625 26.25C26.5625 22.0728 28.2416 18.0668 31.2305 15.1131C34.2193 12.1594 38.2731 10.5 42.5 10.5C46.7269 10.5 50.7807 12.1594 53.7695 15.1131C56.7584 18.0668 58.4375 22.0728 58.4375 26.25V36.75H26.5625V26.25ZM74.375 89.25C74.375 90.6424 73.8153 91.9777 72.819 92.9623C71.8227 93.9469 70.4715 94.5 69.0625 94.5H15.9375C14.5285 94.5 13.1773 93.9469 12.181 92.9623C11.1847 91.9777 10.625 90.6424 10.625 89.25V52.5C10.625 51.1076 11.1847 49.7723 12.181 48.7877C13.1773 47.8031 14.5285 47.25 15.9375 47.25H69.0625C70.4715 47.25 71.8227 47.8031 72.819 48.7877C73.8153 49.7723 74.375 51.1076 74.375 52.5V89.25Z"
              fill="#75A8DD"
            />
          </svg>
          <h2 className="unauth-title">Not Authorized</h2>
          <p className="unauth-message">
            You are not authorized to view this page. If you feel this is an
            error, you can request access.
            <span className="unauth-error-code">(Error 403)</span>
          </p>
          <div className="unauth-button-container">
            <Button
              label={
                accessRequestSuccessful ? 'Request Received' : 'Request Access'
              }
              type="secondary"
              onClick={handleRequestAccessClick}
              className="unauth-left-button"
              disabled={accessRequestSuccessful}
            ></Button>
            <Link to="/home">
              <Button
                label="Sensing Home"
                type="secondary"
                className="unauth-right-button"
              ></Button>
            </Link>
          </div>
        </div>
        <>
          {requestAccessModalOpen && (
            <RequestAccessModal
              opened={requestAccessModalOpen}
              onRequestAccessModalClosed={() =>
                setRequestAccessModalOpen(false)
              }
              accessRequestSuccessful={accessRequestSuccessful}
            />
          )}
        </>
      </Container>
    </BrowserRouter>
  );
};

export default UnauthorizedPage;
